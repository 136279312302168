import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { bbox, centroid } from '@turf/turf';
import axios from 'axios';
import moment from 'moment';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  allWorkCyclesStatus: APIStatus.idle,
  allWorkCycles: null,
  allWorkCyclesError: null,
  workCyclesStatus: APIStatus.idle,
  workCycles: null,
  workCyclesError: null,
  activeWorkCyclesStatus: APIStatus.idle,
  activeWorkCycles: null,
  activeWorkCyclesError: null,
  workCycleCommentStatus: APIStatus.idle,
  workCycleComment: null,
  workCycleCommentError: null,
  detailMapCoverageDataStatus: APIStatus.idle,
  detailMapCoverageData: null,
  detailMapCoverageDataError: null,
  locationCommentsStatus: APIStatus.idle,
  locationComments: null,
  locationCommentsError: null,
  allLocationListStatus: APIStatus.idle,
  allLocationList: null,
  allLocationWorkcycleKey: null,
  metrics: null,
  selectedDateRange: null,
  allLocationListError: null,
  detailMapDataStatus: APIStatus.idle,
  detailMapData: null,
  detailMapDataError: null,
  postCommentStatus: APIStatus.idle,
  postCommentReceipt: null,
  postCommentError: null,
  mapBounds: null,
  selectedDashboardTab: 0,
};

export const getAllWorkCycles = createAsyncThunk('workCyclesSlice/getAllWorkCycles', async({organization}) => {
  const response = await axios.get(`/${organization}/work-cycles`);

  return response.data;
});

export const getWorkCycles = createAsyncThunk('workCyclesSlice/getWorkCycles', async({ organization, offset, searchText }) => {
  const limit = 50;
  const response = await axios.get(`/${organization}/work-cycles`, {
    params: {
      ordering: 'cycle_status',
      limit: 50,
      offset: limit * offset,
      search: searchText
    }
  });
  return response.data;
});

export const getActiveWorkCycles = createAsyncThunk('workCyclesSlice/getActiveWorkCycles', async({ organization }) => {
  const response = await axios.get(`/${organization}/work-cycles`, {
    params: {
      cycle_status: 'active'
    }
  });
  return response.data;
});

export const getWorkCycleComment = createAsyncThunk('workCyclesSlice/getWorkCycleComment', async({ workCycleKey, commentId }) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/work-cycles/${workCycleKey}/comments/${commentId}`);
  return response.data;
});

export const getDetailMapCoverageData = createAsyncThunk('workCyclesSlice/getDetailMapCoverageData', async({ locationDetails, workCycleKey }) => {
  let locKey = (locationDetails.properties.loc_cd) ? locationDetails.properties.loc_cd : locationDetails.properties.key;
  const response = await axios.get(`/${localStorage.getItem('organization')}/work-cycles/${workCycleKey}/locations/${locKey}/daily`);
  return {
    coverageSplitup: (response.data && response.data.results) ? response.data.results.filter(w => w.total_duration) : [], //Object.values(dateMap),
    workCycleKey
  };
});

export const getLocationComments = createAsyncThunk('workCyclesSlice/getLocationComments', async({ location, workCycleKey }) => {

  let locationKey = location && (location.properties.loc_cd) ? location.properties.loc_cd : location.properties.key;

  let apiEndPoint;
  if (workCycleKey === null) {
    apiEndPoint = `/${localStorage.getItem('organization')}/comments?location__key=${locationKey}`;
  } else {
    apiEndPoint = `/${localStorage.getItem('organization')}/work-cycles/${workCycleKey}/comments?location_key=${locationKey}`;
  }

  const response = await axios.get(apiEndPoint);
  return response.data;
});

export const getAllLocationList = createAsyncThunk('workCyclesSlice/getAllLocationList', async({ dateRange, contractorsArr, workCycleKey }) => {

  let dateArr = [];
  let reqArr = [];
  let noOfDays = moment(dateRange[1]).diff(dateRange[0], 'days') + 1;
  let startDate = moment(dateRange[0]);
  let endDate = moment(dateRange[1]);
  let isEndDateToday = endDate.isSame(moment(), 'day');
  let isBetweenWorkCycle = moment().isBetween(startDate, endDate);
  if (isEndDateToday === true) {
    isBetweenWorkCycle = true;
  }
  if (isBetweenWorkCycle) {
    reqArr.push(axios.get(`/${localStorage.getItem('organization')}/today-locations/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err.response;
      }));
  }

  if (workCycleKey) {
    reqArr.push(axios.get(`/${localStorage.getItem('organization')}/work-cycles/${workCycleKey}/locations?cumulative_area_covered__gt=0`)
      .then(res => { return res.data; }).catch(err => { return err.response; }));
  }

  let locationCDArr = [];
  const response = await axios.all(reqArr).then(axios.spread((...responses) => {

    for (let r = 0; r < responses.length; r++) {
      if (responses[r]) {
        if (isBetweenWorkCycle && r === 0) {
          if (responses[r] && responses[r].length > 0) {
            locationCDArr = responses[r].filter(l => l[0] !== null);
          }
          else {
            locationCDArr = responses[r].filter(l => l !== null);
          }
        }

      }
    }
    return getAllLocationGeoJsons(locationCDArr, contractorsArr, dateRange, responses, dateArr, isEndDateToday, noOfDays, isBetweenWorkCycle);
  }));

  return response;
});

async function getAllLocationGeoJsons(locationCDArr, contractorsArr, dateRange, prevDayResponses, dateArr, isEndDateToday, noOfDays, isBetweenWorkCycle) {
  let polygonReqArr = [];
  let currentWorkCycleLocs = [];
  let locCdAr = [];
  prevDayResponses.forEach((res, idx) => {
    if (isBetweenWorkCycle && idx === 0) {
      if (locationCDArr.length > 0) {
        locCdAr = locationCDArr.map(d => d[0]);
        currentWorkCycleLocs = prevDayResponses[1].results.filter(item => locCdAr.includes((item.location_key) ? item.location_key : item.key));
        locCdAr = currentWorkCycleLocs.map(loc => {
          return (loc.location_key) ? loc.location_key : loc.key;
        });
        locationCDArr = locationCDArr.filter(item => locCdAr.includes(item[0]));
        let locationKey = locCdAr[0];
        if (locCdAr.length > 1) {
          locationKey = locCdAr.join(',');
        }
        if (locCdAr.length > 0) {
          polygonReqArr.push(axios.get(`/${localStorage.getItem('organization')}/locations?key=${locationKey}`)
            .then(res => {
              return res.data.results;
            })
            .catch(err => {
              return err.response;
            }));
        }

      }
    } else {
      if (res && res.results) {
        // console.log(res,'re')
        if (res && res.results.length > 0) {
          if (!res.features) {
            res.features = res.results;
          }
          polygonReqArr.push(res);
        }
      }
      if (res && res.file_s3_url) {
        polygonReqArr.push(axios.get((res) ? res.file_s3_url : '', {
          transformRequest: (data, headers) => {
            delete headers.common.Authorization;
          }
        }).then(res => {
          return res.data;
        }).catch(err => {
          return err.response;
        }));
      }
    }

  });

  const allReq = await axios.all(polygonReqArr).then(axios.spread((...responses) => {
    let locations = [];
    let TotalDevices = 0;
    let DevicesList = {};
    let metrics = {};
    let TotalPolygonArea = 0;
    let TotalGrassCutArea = 0;
    let TotalCompletedLocs = 0;
    // console.log(responses, 'RESPONSE')
    for (let r = responses.length - 1; r >= 0; r--) {
      if (responses[r] && responses[r].features) {
        let isTodayData = (r === 0 && locationCDArr.length > 0);
        let contractor = contractorsArr[0];
        // metrics = {}
        if (isTodayData) {
          metrics.hasTodayData = true;
        }
        //let filteredResponse = responses[r].features.filter(item=>item.cumulative_area_covered && item.cumulative_area_covered > 0);
        let filteredResponse = responses[r].features;
        // eslint-disable-next-line no-loop-func
        filteredResponse.forEach((item) => {
          if (!item.properties) {
            if (item.location_geom !== null) {
              item.geometry = item.location_geom;

            } else {
              item.geometry = null;
            }
            let coverageVal = 0;
            let opHrs = '-';
            let rawopHrs = '-';
            if (item.location_area) {
              coverageVal = (item.cumulative_area_covered / item.location_area) * 100;
              TotalPolygonArea = TotalPolygonArea + item.location_area;
              TotalGrassCutArea = TotalGrassCutArea + item.cumulative_area_covered;
            }
            if (item.total_duration !== null) {
              let totalSecs = item.total_duration;
              rawopHrs = item.total_duration;
              let hrs = parseInt(totalSecs / (60 * 60));
              let remsecs = totalSecs % (60 * 60);
              let mins = parseInt(remsecs / 60);
              // let secs = remsecs % 60;
              opHrs = `${(`00${hrs}`).slice(-2)}:${(`00${mins}`).slice(-2)}`;
              item.total_duration = opHrs;
            }
            item.properties = {
              Coverage: coverageVal,
              Date: item.last_seen_date,
              Device: item.devices_list,
              DeviceCount: item.devices_count,
              GrassCutArea: item.cumulative_area_covered,
              JobCompletion: (item.manual_completion === true) ? '1' : item.status === 'complete' ? '1' : '0',
              jobRawStatus: item.status,
              ManuallyCompleted: item.manual_completion,
              ManuallyCompletedComment: item.manual_completion_comment,
              PolygonArea: item.location_area,
              rawOperationalDuration: rawopHrs,
              TotalOperationalDuration: opHrs,
              loc_cd: item.location_key
            };
            item.type = 'Feature';
          }
          let currentLocKey = (item.properties.location_key) ? item.properties.location_key : item.properties.key;
          let fndLocation = locations.find(l => l.properties.key === currentLocKey);
          if (!item.properties.loc_id) {
            item.properties.loc_id = item.properties.loc_cd;
          }
          if (!item.properties.key) {
            item.properties.key = item.properties.loc_cd;
          }
          if (currentLocKey === 'ALL') {
            metrics = item.properties;

          } else if (!fndLocation) {
            let randomContract = contractor;
            item.properties.contractor = randomContract.contract;
            item.properties.zone = randomContract.zone;
            item.properties.hasTodayData = isTodayData;
            if (isTodayData) {
              item.properties.Date = '';
              item.properties.TotalOperationalDuration = '00:00:00';
              item.properties.GrassCutArea = 0;
              item.properties.Coverage = 0;
              // let locProps = GetLocProps(locationCDArr, item);
              item.properties = { ...item.properties };
              // console.log(item.properties, 'props')
              if (item.bbox && item.bbox.length === 4) {
                item.properties.cornersLatLng = [[item.bbox[0], item.bbox[1]], [item.bbox[2], item.bbox[3]]];
              }
              let center = null;
              if (item.geometry && item.geometry.type === 'MultiPolygon' && item.geometry.coordinates && item.geometry.coordinates.length > 0 && item.geometry.coordinates[0].length > 0 && item.geometry.coordinates[0][0].length > 0 && item.geometry.coordinates[0][0][0].length === 2) {
                center = item.geometry.coordinates[0][0][0];
                item.properties.center = center;
              } else {
                center = centroid(item);
                if (center.geometry && center.geometry.coordinates && center.geometry.coordinates.length === 2) {
                  item.properties.center = center.geometry.coordinates;
                }
              }
            } else {

              item.properties.hasTodayData = isTodayData;
              if (item.properties.Date) {
                //let datesCut = item.properties.Date.split(',');
                item.properties.lastCut = item.properties.Date; // moment(datesCut[datesCut.length - 1], 'YYYYMMDD').format('YYYY-MM-DD');
              }
              item.properties.jobStatus = (item.properties.ManuallyCompleted === true) ? 'Manually Completed' : item.properties.JobCompletion === '1' ? 'Completed' : 'InProgress';
              // item.properties.jobStatus = item.properties.JobCompletion === '1' ? 'Completed' : 'InProgress';
              item.properties.PolygonArea = item.properties.PolygonArea ? +item.properties.PolygonArea.toFixed(3) : 0;
              item.properties.GrassCutArea = item.properties.GrassCutArea ? +item.properties.GrassCutArea.toFixed(3) : 0;
              let Coverage = item.properties.Coverage ? +(parseFloat(item.properties.Coverage)).toFixed(2) : 0;
              if (item.properties.JobCompletion === '1') {
                TotalCompletedLocs += 1;
              }
              if (isNaN(Coverage)) {
                // console.log('item',item)
              } else {
                item.properties.Coverage = Coverage;
              }
              let cutdevices = item.properties.Device && (Array.isArray(item.properties.Device) ? item.properties.Device : item.properties.Device.split(','));
              cutdevices && cutdevices.forEach(dv => {
                DevicesList[dv] = dv;
              });
              let polyBbox = bbox(item);
              item.properties.cornersLatLng = [[polyBbox[0], polyBbox[1]], [polyBbox[2], polyBbox[3]]]; //cornersLatLng;
              let center = null;
              if (item.geometry && item.geometry.type === 'MultiPolygon' && item.geometry.coordinates && item.geometry.coordinates.length > 0 && item.geometry.coordinates[0].length > 0 && item.geometry.coordinates[0][0].length > 0 && item.geometry.coordinates[0][0][0].length === 2) {
                center = item.geometry.coordinates[0][0][0];
                item.properties.center = center;
              } else {
                center = centroid(item);
                if (center.geometry && center.geometry.coordinates && center.geometry.coordinates.length === 2) {
                  item.properties.center = center.geometry.coordinates;
                }
              }
            }
            //if (item.geometry !== null) {
            locations.push(item);
            //}
          } else if (fndLocation) {
            fndLocation.properties.hasTodayData = isTodayData;
            if (fndLocation.properties.hasTodayData) {
              // let locProps = GetLocProps(locationCDArr, fndLocation);
              fndLocation.properties = { ...fndLocation.properties };
              // console.log(fndLocation.properties, 'props')
            }
          }
        });
      }
    }

    TotalDevices = Object.keys(DevicesList).length;
    metrics.TotalDevices = TotalDevices;
    metrics.LocationCount = locations.length;
    metrics.TotalPolygonArea = TotalPolygonArea;
    metrics.TotalGrassCutArea = TotalGrassCutArea;
    metrics.CompletedLocationCount = TotalCompletedLocs;
    if (metrics.TotalGrassCutArea > 0 && metrics.TotalPolygonArea > 0) {
      metrics.CoveragePercentage = parseFloat((metrics.TotalGrassCutArea / metrics.TotalPolygonArea) * 100).toFixed(2);
    } else {
      metrics.CoveragePercentage = '-';
    }

    if (noOfDays === 1 && isEndDateToday && responses.length === 0) {
      metrics = {
        ...metrics,
        CompletedLocationCount: 0,
        LocationCount: locations.length,
        TotalGrassCutArea: 0,
        TotalPolygonArea: 0,
      };
    }
    locations = locations.sort((a, b) => { return b.properties.Coverage - a.properties.Coverage; });
    return { locations, metrics }; //dateRange: dateRange? dateRange.map(dt=>moment(dt).format('YYYY-MM-DD')): null 
  }));

  return allReq;
}

export const getAllLocationDescriptions = async({locationKeys}) => {
  try {
    const res = await axios.get(`/${localStorage.getItem('organization')}/locations?key=${locationKeys}`);
    return res.data;
  } catch (error) {
    console.error('Error fetching location descriptions:', error);
    throw error;
  }
};

// eslint-disable-next-line no-unused-vars
function GetLocProps(locationCDArr, loc) {
  let fndLoc = locationCDArr.find(l => l[0] === loc.properties.key);
  let PolygonArea = loc.properties.polygon_area ? loc.properties.polygon_area : loc.properties.PolygonArea;
  let cutCount = fndLoc && fndLoc[2];
  let cutArea = cutCount * 0.5;
  let cov = (cutArea * 100) / PolygonArea;
  let opData = loc.properties.TotalOperationalDuration && loc.properties.TotalOperationalDuration.split(':');
  let oprHrs = '-';
  let totalSecs = '-';
  if (opData && opData[0] && opData[0] !== '-') {
    let pSec = (parseInt(opData[0]) * 60 * 60) + (parseInt(opData[1]) * 60) + parseInt(opData[2]);
    totalSecs = pSec + cutCount;
    let hrs = parseInt(totalSecs / (60 * 60));
    let remsecs = totalSecs % (60 * 60);
    let mins = parseInt(remsecs / 60);
    // let secs = remsecs % 60;
    oprHrs = `${(`00${hrs}`).slice(-2)}:${(`00${mins}`).slice(-2)}`;
  }

  cov += parseFloat(loc.properties.Coverage);
  if (cov > 100) cov = 100;
  if (isNaN(cov) || cov < 0) cov = 0;

  let TotalGrassCutArea = cutArea + parseFloat(loc.properties.GrassCutArea);
  if (TotalGrassCutArea > PolygonArea) TotalGrassCutArea = PolygonArea;
  // console.log(loc.Date,loc)
  let locProps = {
    key: loc.properties.key,
    dataLen: cutCount,
    loc_cd: loc.properties.key,
    GrassCutArea: +TotalGrassCutArea.toFixed(3),
    Coverage: +cov.toFixed(2),
    TotalOperationalDuration: oprHrs,
    rawOperationalDuration: totalSecs,
    // JobCompletion: (cov < 80) ? "0" : "1",
    JobCompletion: (loc.manual_completion === true) ? '1' : (cov < 80) ? '0' : '1',
    ManuallyCompleted: loc.manual_completion,
    jobRawStatus: (loc.manual_completion === true) ? 'complete' : (cov < 80) ? 'open' : 'complete',
    ManuallyCompletedComment: loc.manual_completion_comment,
    // jobStatus: (cov < 80) ? "InProgress" : "Completed",
    jobStatus: (loc.manual_completion === true) ? 'Manually Completed' : (cov < 80) ? 'InProgress' : 'Completed',
    lastCut: moment(fndLoc && fndLoc[1]).format('YYYY-MM-DD'), //moment().format('YYYY-MM-DD'),
    Date: loc.Date ? (`${loc.Date},${moment().format('YYYYMMDD')}`) : moment().format('YYYYMMDD'),
    PolygonArea: +PolygonArea.toFixed(3),
    lastSeen: fndLoc && fndLoc[1]
  };

  return locProps;
}

export const getDetailMapData = createAsyncThunk('workCyclesSlice/getDetailMapData', async({ locationDetails, workDates }) => {

  let mapData = [];

  let cutDates = locationDetails.properties.Date && locationDetails.properties.Date.split(',');
  cutDates = cutDates && cutDates.filter(function(item, r) {
    return cutDates.indexOf(item) === r;
  });
  let key = (locationDetails.properties.loc_cd) ? locationDetails.properties.loc_cd : locationDetails.properties.key;
  const locres = await axios.get(`/${localStorage.getItem('organization')}/locations?key=${key}`);

  if (locres.data.results.features[0]) {
    if (!locationDetails.properties.loc_id || (locationDetails.properties.loc_id === undefined || locationDetails.properties.loc_id === null)) {
      locationDetails.properties.loc_id = locres.data.results.features[0].id;
    }
    let detailRawDataFileReqArr = [];
    for (let d = 0; d < workDates.length; d++) {
      // let targetDate = moment(dateRange[0]).add(d, 'days');
      let targetDate = moment(workDates[d], 'YYYYMMDD');
      let isTodayDate = targetDate.isSame(moment(), 'day');
      if (!isTodayDate) {
        let formattedDate = targetDate.format('YYYY-MM-DD');

        detailRawDataFileReqArr.push(axios.get(`/${localStorage.getItem('organization')}/location-processed-data?location_key=${key}&file_created_at=${formattedDate}&file_type=location_processed_data`)
          .then(res => {
            let fData = res.data.results.filter(l => {
              return l.file_type === 'location_processed_data';
            });
            return fData;
          })
          .catch(err => {
            return err.response;
          }));
      }

    }

    let fileResponses = await axios.all(detailRawDataFileReqArr);

    // dispatch({ type: 'SET_LOADER' });

    let dataReqArr = fileResponses.filter(f => f && f.length > 0).map(f => {
      return axios.get(`/${localStorage.getItem('organization')}/location-processed-data/${f[0].id}`)
        .then(res => {
          return res.data;
        })
        .catch(err => {
          return err.response;
        });
    });

    const responses = await axios.all(dataReqArr);

    let reqArr = [];
    let isDataFound = responses.find(d => d ? true : false);
    if (isDataFound) {
      for (let r = 0; r < responses.length; r++) {
        if (responses[r]) {
          reqArr.push(axios.get(responses[r].file_s3_url, {
            transformRequest: (data, headers) => {
              delete headers.common.Authorization;
            }
          }).then(res => {
            return csvJSON(res.data);
          }).catch(err => {
            return err.response;
          }));
        }
      }

      const mresponses = await axios.all(reqArr);

      // console.log('reqArr', responses);
      let prevData = mresponses.map(dt => {
        return {
          isToday: false,
          data: dt
        };
      });
      mapData = mapData.concat(prevData);

      return await getTodayDetailData(mapData, locationDetails);
      // dispatch({ type: 'CLEAR_LOADER' });

    }
    return await getTodayDetailData(mapData, locationDetails);
    // dispatch({ type: 'CLEAR_LOADER' });

  }
});

async function getTodayDetailData(mapData, locationDetails) {
  if (locationDetails.properties.hasTodayData) {
    // dispatch({ type: 'SET_LOADER' });
    //get today data
    var startDate = moment().format('YYYY-MM-DD');
    var endDate = moment().format('YYYY-MM-DD');
    var startTime = `${startDate} 00:00`;
    var endTime = `${endDate} 23:59`;

    var utcstartDate = moment(startTime).utc().format('YYYY-MM-DD HH:mm:ss');
    var utcendDate = moment(endTime).utc().format('YYYY-MM-DD HH:mm:ss');
    let offsetVal = 0;
    try {
      const res = await axios.get(`/${localStorage.getItem('organization')}/locations/${locationDetails.properties.key}/stream-data?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`, { headers: { accept: 'application/json' } });

      // dispatch({ type: 'SET_LOADER' });
      let totalCount = res.data.count;
      let noofLoops = (totalCount / 3000); //- 1
      let streamdatareq = [];
      let streamdatares = [];
      streamdatares.push(res.data.results);
      if (noofLoops > 1) {
        let loopInt = Number(noofLoops.toFixed(0));
        //let loopDecimal = Number(noofLoops.toFixed(1))
        let forloops = loopInt;
        // if (loopDecimal > loopInt) {
        //     forloops = (Number(loopInt) + 1)
        // }
        // forloops = forloops - 1
        for (let j = 0; j < forloops; j++) {
          offsetVal = offsetVal + 3000;
          streamdatareq.push(axios.get(`/${localStorage.getItem('organization')}/locations/${locationDetails.properties.key}/stream-data?completed_at__range=${utcstartDate},${utcendDate}&limit=3000&offset=${offsetVal}`));
        }
        const streamResp = await axios.all(streamdatareq);

        streamResp.forEach(s => {
          streamdatares.push(s.data.results);
        });
        if (streamdatares.length > 1) {
          var allDataArr = [].concat.apply([], streamdatares);
          mapData.push({
            isToday: true,
            data: allDataArr
          });
          let isDataFound = mapData.find(d => d.data.length > 0);
          if (isDataFound) {
            return mapData;
            // dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
            // dispatch({ type: 'CLEAR_LOADER' });
          }
          throw new Error('No data found');
          // dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
          // dispatch({ type: 'CLEAR_LOADER' });

        }

      }
      else {
        mapData.push({
          isToday: true,
          data: res.data.results
        });
        let isDataFound = mapData.find(d => d.data.length > 0);
        if (isDataFound) {
          return mapData;
          // dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
          // dispatch({ type: 'CLEAR_LOADER' });
        }
        throw new Error('No data found');
        // dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
        // dispatch({ type: 'CLEAR_LOADER' });

      }

    } catch (err) {
      let isDataFound = mapData.find(d => d.data.length > 0);
      if (isDataFound) {
        return mapData;
        // dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
        // dispatch({ type: 'CLEAR_LOADER' });
      }
      throw new Error('No data found');
      // dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
      // dispatch({ type: 'CLEAR_LOADER' });

    };
  } else {
    let isDataFound = mapData.find(d => d.data && d.data.length && d.data.length > 0);
    if (isDataFound) {
      return mapData;
      // dispatch({ type: "GET_DETAIL_DATA", payload: mapData })
      // dispatch({ type: 'CLEAR_LOADER' });
    }
    throw new Error('No data found');
    // dispatch({ type: "GET_DETAIL_DATA_ERROR", payload: 'No data found' })
    // dispatch({ type: 'CLEAR_LOADER' });

  }
}

function csvJSON(csv) {
  var lines = csv.split('\n');
  var result = [];
  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step 
  // (you might convert them to &&& or something, then covert them back later)
  var headers = lines[0].split(',');
  for (var i = 1; i < lines.length; i++) {
    if (lines[i]) {
      var obj = {};
      var currentline = lines[i].split(',');
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
  }
  return result; //JSON
}

export const postComment = createAsyncThunk('workCyclesSlice/postComment', async({ commentData, workCycleKey }) => {

  let apiEndPoint;
  if (workCycleKey === null) {
    apiEndPoint = `/${localStorage.getItem('organization')}/comments`;
  } else {
    apiEndPoint = `/${localStorage.getItem('organization')}/work-cycles/${workCycleKey}/comments`;
  }

  const response = await axios.post(apiEndPoint, commentData);
  return response.data;

});

const workCyclesSlice = createSlice({
  name: 'workCycles',
  initialState,
  reducers: {
    reset(state) {
      state = { ...initialState };
    },
    setmapBounds(state, action) {
      state.mapBounds = action.payload;
    },
    resetPostComment(state, action) {
      state.postCommentStatus = APIStatus.idle;
      state.postCommentReceipt = null;
      state.postCommentError = null;
    },
    resetDashboard(state) {
      state.allLocationListStatus = APIStatus.idle;
      state.allLocationList = null;
      state.allLocationListError = null;
      state.metrics = null;
      state.allLocationWorkcycleKey = null;
    },
    resetDashboardDetail(state) {
      state.detailMapCoverageDataStatus = APIStatus.idle;
      state.detailMapCoverageData = null;
      state.detailMapCoverageDataError = null;
      state.detailMapDataStatus = APIStatus.idle;
      state.detailMapData = null;
      state.detailMapDataError = null;
      state.locationCommentsStatus = APIStatus.idle;
      state.locationComments = null;
      state.locationCommentsError = null;
      state.workCycleCommentStatus = APIStatus.idle;
      state.workCycleComment = null;
      state.workCycleCommentError = null;
    },
    setSelectedDashboardTab(state, action) {
      state.selectedDashboardTab = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getWorkCycles.pending, (state, action) => {
        state.workCyclesStatus = APIStatus.loading;
        state.workCycles = null;
      })
      .addCase(getWorkCycles.fulfilled, (state, action) => {
        state.workCycles = action.payload;
      })
      .addCase(getWorkCycles.rejected, (state, action) => {
        state.workCyclesStatus = APIStatus.failed;
        state.workCyclesError = action.error;
      })
      .addCase(getActiveWorkCycles.pending, (state, action) => {
        state.activeWorkCyclesStatus = APIStatus.loading;
        state.activeWorkCycles = null;
      })
      .addCase(getActiveWorkCycles.fulfilled, (state, action) => {
        state.activeWorkCyclesStatus = APIStatus.loaded;
        state.activeWorkCycles = action.payload.results;
      })
      .addCase(getActiveWorkCycles.rejected, (state, action) => {
        state.activeWorkCyclesStatus = APIStatus.failed;
        state.activeWorkCyclesError = action.error;
      })
      .addCase(getWorkCycleComment.pending, (state, action) => {
        state.workCycleCommentStatus = APIStatus.loading;
        state.workCycleComment = null;
      })
      .addCase(getWorkCycleComment.fulfilled, (state, action) => {
        state.workCycleCommentStatus = APIStatus.loaded;
        state.workCycleComment = action.payload;
      })
      .addCase(getWorkCycleComment.rejected, (state, action) => {
        state.workCycleCommentStatus = APIStatus.failed;
        state.workCycleCommentError = action.error;
      })
      .addCase(getDetailMapCoverageData.pending, (state, action) => {
        state.detailMapCoverageDataStatus = APIStatus.loading;
        state.detailMapCoverageData = null;
      })
      .addCase(getDetailMapCoverageData.fulfilled, (state, action) => {
        state.detailMapCoverageDataStatus = APIStatus.loaded;
        state.detailMapCoverageData = action.payload;
      })
      .addCase(getDetailMapCoverageData.rejected, (state, action) => {
        state.detailMapCoverageDataStatus = APIStatus.failed;
        state.detailMapCoverageDataError = action.error;
      })
      .addCase(getLocationComments.pending, (state, action) => {
        state.locationCommentsStatus = APIStatus.loading;
        state.locationComments = null;
      })
      .addCase(getLocationComments.fulfilled, (state, action) => {
        state.locationCommentsStatus = APIStatus.loaded;
        state.locationComments = action.payload;
      })
      .addCase(getLocationComments.rejected, (state, action) => {
        state.locationCommentsStatus = APIStatus.failed;
        state.locationCommentsError = action.error;
      })
      .addCase(getAllWorkCycles.pending, (state, action) => {
        state.allWorkCyclesStatus = APIStatus.loading;
        state.allWorkCycles = null;
      })
      .addCase(getAllWorkCycles.fulfilled, (state, action) => {
        state.allWorkCyclesStatus = APIStatus.loaded;
        state.allWorkCycles = action.payload;
      })
      .addCase(getAllWorkCycles.rejected, (state, action) => {
        state.allWorkCyclesStatus = APIStatus.failed;
        state.allWorkCyclesError = action.error;
      })
      .addCase(getAllLocationList.pending, (state, action) => {
        state.allLocationListStatus = APIStatus.loading;
        state.allLocationList = null;
        state.metrics = null;
        state.allLocationWorkcycleKey = action.meta.arg.workCycleKey;
      })
      .addCase(getAllLocationList.fulfilled, (state, action) => {
        state.allLocationListStatus = APIStatus.loaded;
        state.allLocationList = action.payload.locations;
        state.metrics = action.payload.metrics ? action.payload.metrics : null;
        // state.selectedDateRange = action.payload.dateRange;
      })
      .addCase(getAllLocationList.rejected, (state, action) => {
        state.allLocationListStatus = APIStatus.failed;
        state.allLocationListError = action.error;
      })
      .addCase(getDetailMapData.pending, (state, action) => {
        state.detailMapDataStatus = APIStatus.loading;
        state.detailMapData = null;
        state.detailMapDataError = null;
      })
      .addCase(getDetailMapData.fulfilled, (state, action) => {
        state.detailMapDataStatus = APIStatus.loaded;
        state.detailMapData = action.payload;
      })
      .addCase(getDetailMapData.rejected, (state, action) => {
        state.detailMapDataStatus = APIStatus.failed;
        state.detailMapDataError = action.error.message;
      })
      .addCase(postComment.pending, (state, action) => {
        state.postCommentStatus = APIStatus.loading;
        state.postCommentReceipt = null;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.postCommentStatus = APIStatus.loaded;
        state.postCommentReceipt = {
          ...action.payload.data,
          author: localStorage.getItem('email')
        };
      })
      .addCase(postComment.rejected, (state, action) => {
        state.postCommentStatus = APIStatus.failed;
        state.postCommentError = action.error;
      });
  }
});

export const { reset, setmapBounds, resetPostComment, resetDashboard, resetDashboardDetail, setSelectedDashboardTab } = workCyclesSlice.actions;

export default workCyclesSlice.reducer;